@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'NotoSansR', '맑은 고딕', sans-serif;
}

ul,
dl,
li,
ol {
  list-style: none;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

select {
  outline: none;
}

input {
  border: none;
  outline: none;
}

a {
  color: black;
  text-decoration: none;
}