@charset "UTF-8";
.self .search-wrap {
  margin: 30px 0;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px 150px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.self .search-wrap * {
  font-size: 14px;
}
.self .search-wrap .search-first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.self .search-wrap .search-first > div {
  margin-right: 30px;
  width: 50%;
}
.self .search-wrap .search-first > div span {
  margin-right: 15px;
  min-width: 80px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.self .search-wrap .search-first > div input {
  width: 440px;
  height: 34px;
  padding: 4px 6px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  min-width: 200px;
}
.self .search-wrap .search-first > div input::placeholder {
  padding-left: 5px;
}
.self .search-wrap .search-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0;
}
.self .search-wrap .search-two > div {
  margin-right: 30px;
  width: 50%;
  padding-left: 10px;
}
.self .search-wrap .search-two > div span {
  margin-left: 15px;
  margin-right: 15px;
  min-width: 80px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.self .search-wrap .search-two > div input {
  width: 440px;
  height: 34px;
  padding: 4px 6px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  min-width: 200px;
}
.self .search-wrap .search-two > div input::placeholder {
  padding-left: 5px;
}
.self .search-wrap .search-two > div.date {
  padding-left: 0;
}
.self .search-wrap .search-two > div.date input {
  width: 150px;
}
.self .search-wrap .search-three {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.self .search-wrap .search-three > div {
  width: 50%;
}
.self .search-wrap .search-three > div span {
  margin-left: 15px;
  margin-right: 15px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.self .search-wrap .search-three > div select {
  width: 250px;
  height: 34px;
  padding-left: 5px;
  border-radius: 4px;
  background-color: #fff;
  border-color: #cdcdcd;
}
.self .search-wrap .search-three > div.btn-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 100px;
}
.self .search-wrap .search-three > div.btn-wrap button {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  padding: 7px 30px;
  background-color: #434343;
  border-radius: 4px;
  color: #fff;
  margin-right: 15px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
}
.self .search-wrap .search-three > div.btn-wrap button:last-child {
  margin-right: 0;
  background-color: #fff;
  color: #15171a;
  border: 1px solid #f0f0f0;
}
.self .table-wrap {
  width: 100%;
}