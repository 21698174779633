@charset "UTF-8";
.agencies .search-wrap {
  margin: 30px 0;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px 150px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.agencies .search-wrap * {
  font-size: 14px;
}
.agencies .search-wrap select {
  padding: 5px 10px;
  margin-right: 30px;
  border-color: #cdcdcd;
  background-color: white;
  border-radius: 4px;
  min-width: 200px;
}
.agencies .search-wrap input {
  height: 34px;
  padding: 4px 6px;
  margin-right: 30px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  min-width: 200px;
}
.agencies .search-wrap input.mark-name-input {
  width: 350px;
}
.agencies .search-wrap input[type=date] {
  margin-right: 5px;
}
.agencies .search-wrap span {
  margin-right: 15px;
  min-width: 80px;
}
.agencies .search-wrap span.tilde {
  min-width: auto;
  text-align: center;
  margin-right: 15px;
  margin-left: 10px;
}
.agencies .search-wrap > .row {
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.agencies .search-wrap > .row select:last-child {
  margin-right: 0;
}
.agencies .search-wrap > .row.mark-name {
  justify-content: space-between;
}
.agencies .search-wrap > .row.mark-name .btn-wrap button {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  padding: 7px 30px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
}
.agencies .search-wrap > .row.mark-name .btn-wrap button:first-child {
  background-color: #434343;
  color: white;
  margin-right: 10px;
}
.agencies .search-wrap > .row:last-child {
  margin-bottom: 0;
}
.agencies .table-wrap thead .sortBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.agencies .table-wrap thead .sortBtn svg {
  margin-left: 5px;
}

.agencies-detail .detail-page h5 {
  margin: 20px 0;
  font-size: 20px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  font-weight: normal;
  color: #434343;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 2px solid #434343;
}
.agencies-detail .detail-page h5::before {
  content: ">";
  margin-right: 10px;
}
.agencies-detail .detail-page button {
  background-color: #434343;
  color: white;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  padding: 5px 10px;
  border-radius: 6px;
}
.agencies-detail .detail-page .table-wrap table thead {
  background-color: rgba(255, 255, 255, 0.4);
}
.agencies-detail .detail-page .table-wrap table tr {
  cursor: auto;
}
.agencies-detail .detail-page .table-wrap table tr th {
  font-family: "NotoSansR", "맑은 고딕", "sans-serif";
}
.agencies-detail .detail-page .table-wrap table tr th,
.agencies-detail .detail-page .table-wrap table tr td {
  border: 1px solid #cdcdcd;
  padding: 7px 30px;
  text-align: left;
}
.agencies-detail .detail-page .table-wrap table tr th button,
.agencies-detail .detail-page .table-wrap table tr td button {
  margin-left: 10px;
}
.agencies-detail .detail-page .table-wrap table tr th .row,
.agencies-detail .detail-page .table-wrap table tr td .row {
  justify-content: flex-start;
}
.agencies-detail .detail-page .table-wrap table tr th .column,
.agencies-detail .detail-page .table-wrap table tr td .column {
  align-items: flex-start;
}
.agencies-detail .detail-page .table-wrap table tr .title {
  text-align: center;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  background-color: #dddddd;
}
.agencies-detail .detail-page .table-wrap table tr .title span {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
}
.agencies-detail .detail-page .table-wrap table tr .title .row {
  justify-content: center;
}
.agencies-detail .detail-page .table-wrap table tr:hover td {
  color: black;
}
.agencies-detail .detail-page .table-wrap.payment-status {
  margin-top: 10px;
}
.agencies-detail .detail-page .table-wrap.payment-status .addPay .column input {
  width: 500px;
  padding: 3px 5px;
  border-radius: 3px;
  margin-bottom: 10px;
}
.agencies-detail .detail-page .table-wrap.payment-status .addPay .column input:last-child {
  margin-bottom: 0;
}
.agencies-detail .detail-page .table-wrap.payment-status select {
  padding: 5px 0;
}
.agencies-detail .detail-page .table-wrap.payment-status select option {
  padding: 3px 0;
}
.agencies-detail .detail-page .table-wrap.payment-status select option.red {
  color: #f74848;
}
.agencies-detail .detail-page .table-wrap.payment-temp-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.agencies-detail .detail-page .table-wrap.payment-temp-list table tr td.payment-btn .column {
  align-items: center;
  justify-content: center;
}
.agencies-detail .detail-page .table-wrap.payment-temp-list table tr td.payment-btn .column button {
  margin-top: 10px;
  margin-left: 0;
}
.agencies-detail .detail-page .table-wrap.payment-temp-list > button {
  margin-top: 10px;
}
.agencies-detail .detail-page .table-wrap.application-info {
  margin-top: 20px;
}
.agencies-detail .detail-page .table-wrap.applicant-info th,
.agencies-detail .detail-page .table-wrap.applicant-info td {
  text-align: left;
}
.agencies-detail .detail-page .add-info {
  justify-content: flex-start;
}
.agencies-detail .detail-page .add-info .hyphen {
  margin: 0px 8px;
}
.agencies-detail .detail-page .add-info .input-wrap {
  margin: 0 10px;
}
.agencies-detail .detail-page .add-info input {
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid #cdcdcd;
}
.agencies-detail .detail-page .add-info input.first {
  width: 50px;
}
.agencies-detail .detail-page .add-info input.middle {
  width: 70px;
}
.agencies-detail .detail-page .add-info input.last {
  width: 120px;
}
.agencies-detail .detail-page .add-info input[type=date] {
  margin: 0 10px;
}
.agencies-detail .detail-page .add-info .application-date {
  margin-left: 10px;
}
.agencies-detail .detail-page .add-info button {
  margin-left: 30px;
}
.agencies-detail .detail-page .applicant-info-wrap {
  height: 500px;
  border: 1px solid #434343;
  border-radius: 6px;
  margin-top: 30px;
}